import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {MOBILE_SIZE} from "../../../core/constants/common";
import {getTradeTypeColor} from "../../../core/utils/theme";
import {useMainContext} from "../../../core/contexts/main";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";


const PanelBarChart = ({
    data,
    period
}) => {

    const { width } = useWindowSize()
    const { theme } = useMainContext()

    return(
        <ResponsiveContainer width={'100%'} minWidth={'600px'} height={300}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="name" padding={{ left: 10, right: 10 }}  />
                <YAxis />
                <Legend />
                <Tooltip cursor={{fill: theme === 'dark' ? '#0d1726' : '#fafafa'}}/>
                <Bar barSize={width > MOBILE_SIZE ? 25 : 10} dataKey={period} fill={getTradeTypeColor(period)} />
            </BarChart>
        </ResponsiveContainer>
    )

}


export default PanelBarChart
